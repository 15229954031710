import React from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, NetworkDetailQuery } from "../../../../graphql-types"
import { useFormatLocale, useSlugValue } from "../../hooks"
import Layout from "../../components/layout/layout"
import Banner from "../../components/banner/banner"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: NetworkDetailQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
    allSlugLocales: { locale: string; value: string }[]
  }
}

const NetworkDetails: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={useSlugValue(pageContext.slugs._allDetailNetworkSlugLocales, pageContext.locale)}
      pageTemplate="network details"
      allSlugLocales={pageContext.allSlugLocales}
    >
      <Banner title={data.datoCmsTransportNetwork.networkName} image={null} />
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsTransportNetwork.titleTag}
      description={data.datoCmsTransportNetwork.metaDescription}
      noindex={data.datoCmsTransportNetwork.noindex}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <HrefLangLinks
        defaultDomain={window.location.origin}
        defaultLocale={pageContext.defaultLocale}
        slugs={[pageContext.slugs._allDetailNetworkSlugLocales, pageContext.allSlugLocales]}
      />
    )}
  </>
)

export const pageQuery = graphql`
  query NetworkDetail($locale: String!, $id: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsTransportNetwork(locale: $locale, id: { eq: $id }) {
      titleTag
      metaDescription
      noindex
      networkName
    }
  }
`

export default NetworkDetails

import React from "react"
import { graphql } from "gatsby"
import { IframeFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./iframe.module.scss"

type RenderProps = {
  data: IframeFieldsFragment
}

const IFrame: React.FC<RenderProps> = ({ data }) => {
  const parseStyles = (stylesToParse: string): { [property: string]: string } => {
    return stylesToParse.split(";").reduce((parsedStyles, style) => {
      const splitted = style.split(":")
      if (splitted[0] && splitted[1]) {
        return { ...parsedStyles, [splitted[0].trim()]: splitted[1].trim() }
      } else {
        return parsedStyles
      }
    }, {})
  }

  return (
    <div className={styles.container}>
      <iframe
        title={data.iframe.name}
        src={data.iframe.url}
        style={parseStyles(data.iframe.style)}
        tabIndex={-1}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation allow-modals"
        loading="lazy"
      />
    </div>
  )
}

export const fragment = graphql`
  fragment IframeFields on DatoCmsIframe {
    iframe {
      name
      url
      style
    }
  }
`

export default IFrame
